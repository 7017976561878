/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyCNBitJJV8AF906tVuLoDpQfxdiP7mPnLI",
  "appId": "1:791933278441:web:ad2ef7c1a1c7bfb5b83878",
  "authDomain": "schooldog-i-chattahoochee-ga.firebaseapp.com",
  "measurementId": "G-S7XH94T6D3",
  "messagingSenderId": "791933278441",
  "project": "schooldog-i-chattahoochee-ga",
  "projectId": "schooldog-i-chattahoochee-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-chattahoochee-ga.appspot.com"
}
